.logo {
  width: 100px;
  height: 90px;
  opacity: 1;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
}

.appLogoContainer .textContainer {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.appName {
  text-align: left;
  font-family: "LexendExa Extra Bold", cursive;
  font-size: 4.5em;
  letter-spacing: -6px;
  color: #07020D;
  line-height: 1;
}

.slogan {
  margin-top: 10px;
  text-align: center;
  font: "Bold 1em LexendExa light";
  font-style: italic;
  letter-spacing: 0px;
  color: #4c4b4b;
  opacity: 1;
  line-height: 1;
}

@media only screen and (max-width: 579px) {

  .logo {
    width: 80px;
    height: 90px;
  }

}