@font-face {
    font-family: 'LexendExa Regular';
    font-style: normal;
    font-weight: normal;
    src: local('LexendExa Regular'), url('LexendExa-Regular.ttf');
}


@font-face {
    font-family: 'LexendExa Italic';
    font-style: italic;
    font-weight: normal;
    src: local('LexendExa Italic'), url('LexendExa-Regular.ttf');
}


@font-face {
    font-family: 'LexendExa Semi Bold';
    font-style: normal;
    font-weight: normal;
    src: local('LexendExa Semi Bold'), url('LexendExa-SemiBold.ttf');
}

@font-face {
    font-family: 'LexendExa Bold';
    font-style: normal;
    font-weight: normal;
    src: local('LexendExa Bold'), url('LexendExa-Bold.ttf');
}

@font-face {
    font-family: 'LexendExa Bold Italic';
    font-style: italic;
    font-weight: normal;
    src: local('LexendExa Bold Italic'), url('LexendExa-Bold.ttf');
}

@font-face {
    font-family: 'LexendExa Extra Bold';
    font-style: normal;
    font-weight: normal;
    src: local('LexendExa Extra Bold'), url('LexendExa-ExtraBold.ttf');
}

@font-face {
    font-family: 'LexendExa Thin';
    font-style: normal;
    font-weight: normal;
    src: local('LexendExa Thin'), url('LexendExa-Thin.ttf');
}

@font-face {
    font-family: 'LexendExa Light';
    font-style: normal;
    font-weight: normal;
    src: local('LexendExa Light'), url('LexendExa-Light.ttf');
}

@font-face {
    font-family: 'LexendExa Medium';
    font-style: normal;
    font-weight: normal;
    src: local('LexendExa Medium'), url('LexendExa-Medium.ttf');
}