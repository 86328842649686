@import url("fonts/style.css");

body {
  font-family: 'LexendExa Regular', sans-serif;
  margin: 0 auto;
  min-height: 100%;
  background: #FCFCFC;
}

input {
  height: 57px !important;
}

a {
  color: #2993FC;
}

hr {
  margin-top: .8rem;
  border-color: #F2FFF7;
  opacity: .2
}

.hrContainer {
  width: 40%;
}


a:hover {
  color: #2993FC;
}

.page-title {
  font-weight: Bold;
}

/* HEADER */
.nav-link {
  color: #2993FC !important;
}

button {
  height: 57px;
  font-size: 24px !important;
  font-family: 'Nanum Gothic', sans-serif;
  width: 307px;
  margin-left: 24px;
  background: #2993FC 0% 0% no-repeat padding-box;
  border-color: #2993FC !important;
  border-radius: 10px !important;
  box-shadow: 0px 4px 8px #00000029;
  border-width: 2px !important;
}

.homeContent {
  width: 100%;
  margin-top: 0;
  /* padding-bottom: 100px; */
}

.privacyContent {
  width: 100%;
  margin-top: 50px;
}

.hideOnMobile {
  display: block;
}

.showOnMobile {
  display: none;
}

/* HOME SECTION */
.homeSection {
  background: #F2FFF7;
}

.homeSection .content {
  padding: 50px 50px 20px 50px;
  background-image: url(./images/homesection-bg.svg);
  background-repeat: no-repeat;
  background-size: 120%;
  background-position-x: 100%;
  background-position-y: 10%;
}

.homeSection .downloadContainer {
  margin-top: 100px;
  height: 105px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.homeSection .description {
  margin: 50px 50px 50px 0;
  text-align: left;
  font-family: 'LexendExa Regular', sans-serif;
  /* font-weight: 900; */
  font-size: 2em;
  color: #07020D;
  line-height: 1.5;
  letter-spacing: -3px;
  opacity: 1;
}

.homeSection .buttonPaddingLeft {
  padding-left: 20px;
}

.homeSection .description .productType {
  font-size: 1em;
  margin-left: 10px;
  margin-right: 10px;
  padding-top: 8px;
  background-color: #07020D;
  color: #F2FFF7;
}

.homeSection .description .productType .productTypeItem {
  margin: 8px;
}

.homeSection .phone {
  background-image: url(./images/phone/home-screen.svg);
  width: 100%;
  height: 520px;
  background-repeat: no-repeat;
  background-size: 85%;
  background-position: center;
  -webkit-filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
  -moz-filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
  -ms-filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
  -o-filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
  filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
}

.appLogoContainer {
  padding: 0px 20px;
  height: 90px;
  display: flex;
  flex-direction: row;
}


/* ABOUT SECTION */
.aboutSection {
  background: #07020D 0% 0% no-repeat padding-box;
}

.aboutSection .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 70px 50px 70px;
  background-image: url(./images/aboutSectionBg.svg);
  background-repeat: no-repeat;
  background-size: 120%;
  background-position-x: 100%;
  background-position-y: 100%;
}

.aboutSection .missionStatement {
  text-align: center;
  font-family: 'LexendExa Regular', sans-serif;
  font-size: 2em;
  letter-spacing: -2px;
  color: #F2FFF7;
  opacity: 1;
}

.aboutSection .infoBoxRow {
  margin-top: 30px;
}

/* FEATURE SECTION */
.featureSection {
  background: #F2FFF7 0% 0% no-repeat padding-box;
}

.featureSection .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 50px 50px 50px;
  background-image: url(./images/featureSectionBg.svg);
  background-repeat: no-repeat;
  background-size: 120%;
  background-position-x: 100%;
  background-position-y: 100%;
}

.featureSection .featureMissionStatement {
  text-align: center;
  font-family: 'LexendExa Regular', sans-serif;
  font-size: 2em;
  letter-spacing: -2px;
  color: #07020D;
  opacity: 1;
}

.featureSection .featureBoxRow {
  width: 100%;
  margin-top: 20px;
  min-height: 500px;
}

.featureSection hr {
  border-color: #07020D;
  opacity: .2
}

.featureSection .phoneBox {
  position: relative;
  height: 500px;
  z-index: 99;
  background-image: url(./images/phone/wallet.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  -webkit-filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
  -moz-filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
  -ms-filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
  -o-filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
  filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
}


/* SCREENSHOTS SECTION */
.screenshotSection {
  background: #07020D 0% 0% no-repeat padding-box;
}

.screenshotSection .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 70px 50px 70px;
  background-image: url(./images/screenshotSectionBg.svg);
  background-repeat: no-repeat;
  background-size: 120%;
  background-position-x: 100%;
  background-position-y: 100%;
}

.screenshotSection .screenshotTitle {
  text-align: center;
  font-family: 'LexendExa Regular', sans-serif;
  font-size: 2em;
  letter-spacing: -2px;
  color: #F2FFF7;
  opacity: 1;
}

.screenshotSection .screenshotBoxRow {
  width: 100%;
  margin-top: 20px;
  min-height: 500px;
  padding-bottom: 20px;
}

.screenshotSection hr {
  border-color: #F2FFF7;
  opacity: .2
}

.screenshotSection .phoneBox {
  width: 250px;
  height: 480px;
  background-repeat: no-repeat;
  background-size: contain;
}

/* DOWNLOAD SECTION */
.downloadSection {
  background: #F2FFF7 no-repeat padding-box;
}

.downloadSection .content {
  padding: 50px 50px 20px 50px;
  background-image: url(./images/downloadSectionBg.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: 100%;
}

.downloadSection .captionBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.downloadSection .downloadContainer {
  margin-top: 50px;
  height: 105px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* align-items: center; */
  max-width: 250px;
}

.downloadSection .title {
  margin: 20px 0px 10px 0;
  text-align: center;
  font-family: 'LexendExa Bold', sans-serif;
  font-weight: 900;
  font-size: 2.5em;
  letter-spacing: -2px;
  color: #07020D;
  line-height: 1.2;
  opacity: 1;
}

.downloadSection .caption {
  text-align: center;
  font-family: 'LexendExa Regular', sans-serif;
  font-size: 2em;
  letter-spacing: -2px;
  color: #07020D;
  opacity: 1;
}

.downloadSection .phone {
  background-image: url(./images/phone/buy-amount.svg);
  width: 100%;
  height: 480px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  -webkit-filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
  -moz-filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
  -ms-filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
  -o-filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
  filter: drop-shadow(0px 16px 10px rgba(29, 73, 47, 0.25));
}

/* PRIVACY POLICY */

.privacyContent .appLogoContainer {
  justify-content: center;
}

.privacyContent .content {
  padding: 50px;
  padding-right: 200px;
  padding-left: 200px;

}

.privacyContent .pageTitle {
  text-align: center;
  font-family: 'LexendExa Bold', sans-serif;
  font-size: 1.5em;
  letter-spacing: -2;
  color: #07020D;
  line-height: 1.2;
  opacity: 1;
  margin-bottom: 10px;
}

/* FOOTER SECTION */
footer {
  background: #07020D no-repeat padding-box;
  color: #F2FFF7;
}

footer .marginLeft20 {
  margin-left: 20px;
}

footer a {
  color: #F2FFF7;
}

footer .content {
  padding: 50px 50px 20px 50px;
}

footer .links {
  margin-bottom: 40px;
  text-align: center;
  font-family: 'LexendExa Light', sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  opacity: 1;
}

footer .contact {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: 'LexendExa Light', sans-serif;
  font-weight: 600;
  font-size: 1em;
  text-align: center;
  line-height: 1.8em;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

footer .hello {
  font-family: 'LexendExa Light', sans-serif;
  font-weight: normal;
}

footer .social {
  font-size: 1.3em;
}

footer .copyright {
  font-family: 'LexendExa Light', sans-serif;
  font-size: .8em;
  text-align: justify;
  line-height: 1.8em;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}


/* ==========================================================================
OWL CAROUSEL
========================================================================== */
.owl-carousel {
  z-index: auto;
}

.owl-carousel .owl-item img {
  display: block;
  width: auto;
}

.owl-carousel .card {
  margin: 8px 0;
  height: calc(100% - 16px);
  display: flex;
  flex-direction: column;
}

.owl-nav .owl-prev {
  left: -54px;
}

.owl-nav .owl-next {
  right: -30px;
}

.owl-nav .owl-prev,
.owl-nav .owl-next {
  background: transparent !important;
  border: transparent;
  height: 48px;
  width: 48px;
  border-radius: 24px !important;
  color: #F2FFF7 !important;
  text-align: center;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none !important;
  z-index: 99;
}

.owl-nav .owl-prev:hover,
.owl-nav .owl-next:hover {
  background-color: #F2FFF7 !important;
  border-color: 1px solid #F2FFF7 !important;
  transform: translateY(-55%);
  transition: all 0.24s ease-in-out;
  box-shadow: 0 20px 40px rgba(117, 16, 48, 0.30);
  color: #07020D !important;
}

.owl-nav .disabled {
  display: none !important;
}

.owl-carousel:hover .owl-nav {
  opacity: 1 !important;
}

.owl-stage-outer {
  border-radius: 16px;
  /* z-index: -1; */
}

.owl-stage {
  display: flex;
}

.owl-dot {
  display: none;
}



/* ==========================================================================
RESPONSIVENESS
========================================================================== */

@media only screen and (max-width: 1440px) {

  /* HOME SECTION */

  .homeSection .phone {
    height: 600px;
    background-size: 65%;
  }


}

@media only screen and (max-width: 1280) {

  /* HOME SECTION */

  .homeSection .phone {
    height: 520px;
    background-size: 68%;
  }


}

@media only screen and (max-width: 1200px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1024px) {
  /* HOME SECTION */

  .homeSection .content {
    background-size: 180%;
    background-position-x: 100%;
    background-position-y: 100%;
  }


  .homeSection .description {
    margin: 50px 0px 20px 0;
    text-align: left;
    font-family: 'LexendExa Light', sans-serif;
    font-weight: 900;
    font-size: 2em;
    color: #07020D;
    letter-spacing: -1px;
    line-height: 1.2;
    opacity: 1;
  }


  /* FEATURE SECTION */

  .featureSection .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 50px 50px 50px;
    background-image: url(./images/featureSectionBg.svg);
    background-repeat: no-repeat;
    background-size: 180%;
    background-position-x: 100%;
    background-position-y: 100%;
  }

  .privacyContent .content {
    padding-right: 100px;
    padding-left: 100px;
  }
}



@media only screen and (max-width: 800px) {

  /* HOME SECTION */

  .homeSection .content {
    background-size: 120%;
    background-position-x: 100%;
    background-position-y: 100%;
  }

  .homeSection .description {
    margin: 30px 0px 30px 0;
    font-size: 1.6em;
    line-height: 2rem;
  }

  .homeSection .downloadContainer {
    margin-top: 0px;
    height: 105px;
  }

  .homeSection .buttonPaddingLeft {
    padding-left: 10px;
  }

  .homeSection .phone {
    height: 380px;
    background-size: 60%;
  }

  /* ABOUT SECTION */

  .aboutSection .content {
    padding: 50px 50px 50px 50px;
  }

  .aboutSection .missionStatement {
    font-size: 1.5em;
  }

  /* FEATURE SECTION */

  .featureSection .content {
    padding: 50px 0px 50px 0px;
    background-size: cover;
    background-position-x: 80%;
    background-position-y: 0;
  }

  .featureSection .featureMissionStatement {
    font-size: 1.6em;
    line-height: 2rem;
  }

  .featureSection .featureBoxRow {
    margin-top: 0px;
  }

  /* SCREENSHOTS SECTION */

  .screenshotSection .content {
    padding: 50px 50px 50px 50px;
    background-size: 150%;
    background-position-x: 25%;
    background-position-y: 120%;
  }

  .screenshotSection .screenshotTitle {
    font-size: 1.6em;
  }

  /* DOWNLOAD SECTION */

  .downloadSection .content {
    background-size: 300%;
    background-position-x: 50%;
    background-position-y: 100%;
  }

  .downloadSection .title {
    margin: 0px 0px 10px 0;
    font-size: 1.6em;
  }

  .downloadSection .caption {
    font-size: 1.4em;
  }

  .downloadSection .downloadContainer {
    margin-top: 20px;
  }

  .downloadSection .phone {
    height: 520px;
    background-size: 80%;
    background-position-x: center;
  }

  /* FOOTER SECTION */

  footer .content {
    padding: 20px 50px 20px 50px;
  }

  .privacyContent .content {
    padding: 20px;
    padding-right: 50px;
    padding-left: 50px;
  }
}



@media only screen and (max-width: 579px) {

  .hrContainer {
    width: 60%;
  }

  .hideOnMobile {
    display: none;
  }

  .showOnMobile {
    display: block;
  }

  /* HOME SECTION */

  .homeSection .content {
    padding: 20px 20px 20px 20px;
    background-size: 400%;
    background-position-x: 30%;
    background-position-y: 100%;
  }

  .homeSection .description {
    margin: 40px 0px 20px 0;
    font-size: 1.6em;
    line-height: 2rem;
  }

  .homeSection .downloadContainer {
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .homeSection .buttonPaddingLeft {
    padding-left: 10px;
  }

  .homeSection .phone {
    height: 520px;
    background-size: 60%;
    background-position: center;
  }


  /* ABOUT SECTION */

  .aboutSection .content {
    padding: 20px;
  }

  .aboutSection .missionStatement {
    font-size: 1.2em;
  }

  .aboutSection .infoBoxRow {
    margin-top: 10px;
  }

  /* FEATURE SECTION */

  .featureSection .content {
    padding: 20px 0px 50px 0px;
    background-size: cover;
    background-position-x: 50%;
    background-position-y: 0;
  }

  .featureSection .featureMissionStatement {
    font-size: 1.2em;
    letter-spacing: -1px;
  }

  .featureSection .featureBoxRow {
    margin-top: 0px;
  }

  .featureSection .phoneBox {
    height: 300px;
    margin-top: 20px;
  }

  /* SCREENSHOTS SECTION */

  .screenshotSection .content {
    padding: 20px;
    background-size: 300%;
    background-position-x: 20%;
    background-position-y: 120%;
  }

  .screenshotSection .screenshotTitle {
    font-size: 1.2em;
  }

  /* DOWNLOAD SECTION */

  .downloadSection .content {
    padding: 30px 20px;
    background-size: 650%;
    background-position-y: 100%;
    background-position-x: 50%;
  }

  .downloadSection .phone {
    height: 520px;
    background-size: 50%;
    background-position-x: center;
  }

  .downloadSection .title {
    margin: 0px 0px 10px 0;
    font-size: 1.5em;
  }

  .downloadSection .caption {
    font-size: 1.2em;
  }

  .downloadSection .downloadContainer {
    margin-top: 20px;
  }

  /* PRIVACY POLICY */

  .privacyContent {
    margin-top: 20px;
  }

  .privacyContent .content {
    padding: 20px;
  }

  /* FOOTER SECTION */

  footer .content {
    padding: 20px 30px;
  }

  footer .links {
    font-size: 1.2em;
    line-height: 1em;
  }

}

@media only screen and (max-width: 400px) {

  /* HOME SECTION */

  .homeSection .description {
    margin: 40px 0px 20px 0;
    font-size: 1.4em;
    line-height: 2rem;
  }

  .homeSection .phone {
    height: 400px;
    background-size: 52%;
  }

  /* ABOUT SECTION */

  .aboutSection .missionStatement {
    font-size: 1.0em;
  }

  /* FEATURE SECTION */

  .featureSection .featureMissionStatement {
    font-size: 1.0em;
    line-height: 1.5rem;
  }

  /* SCREENSHOTS SECTION */

  .screenshotSection .screenshotTitle {
    font-size: 1.0em;
  }

}