.downloadButton {
  margin-top: 10px;
  /* padding: 0px 20px; */
  display: flex;
  flex-direction: row;
  width: 12rem;
  height: 60px;
  background: no-repeat;
  background-size: contain;
  background-position: center;
  opacity: 1;
}

.menuButtonContainer {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: transparent linear-gradient(180deg, #F9F5F5 0%, #CCCCCC 100%) 0% 0% no-repeat padding-box;
}

.menuButtonContainer .icon {
  width: 20px;
  height: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 800px) {

  .downloadButton {
    margin-top: 10px;
    padding: 0px 10px;
    height: 45px;
  }

  .menuButtonContainer {
    width: 30px;
    height: 30px;
    border-radius: 15px;
  }

  .menuButtonContainer .icon {
    width: 15px;
    height: 15px;
  }
}